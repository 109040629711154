import React from 'react'
import '../styles/coursecard.scss'

const RatingCard = ({ rating }) => {
  // Convert the string rating to a number
  const numericRating = parseFloat(rating)

  // Check if the rating is valid (between 0 and 5)
  if (isNaN(numericRating) || numericRating < 0 || numericRating > 5) {
    return null
  }

  // Create an array of 5 stars, initially filled or empty based on the rating
  const stars = []
  for (let i = 1; i <= 5; i++) {
    if (i <= numericRating) {
      const starClassName = i <= numericRating ? 'filled-star' : 'empty-star'
      // Fill the star if its position is less than or equal to the rating
      stars.push(<i key={i} className={`fa fa-star ${starClassName}`} aria-hidden='true'></i>)
    } else {
      // Otherwise, display an empty star
      stars.push(<i key={i} className='fa fa-star-o' aria-hidden='true'></i>)
    }
  }

  return (
    <div className='rating-card' style={{ display: 'flex', alignItems: 'center' }}>
      <div className='rating-icons'>{stars}</div>
      <p></p>
    </div>
  )
}

export default RatingCard
