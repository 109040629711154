import React, { useState } from 'react'
import '../styles/Popup.css' // Import the CSS file for styling

const Popup = () => {
  const [isVisible, setIsVisible] = useState(true)

  const handleOptionSelect = option => {
    document.cookie = `selectedOption=${option}; max-age=31536000; path=/` // Set the cookie
    setIsVisible(false) // Close the popup after selecting an option
  }

  return (
    isVisible && (
      <div className='popup-overlay'>
        <div className='popup-content'>
          <h2 className='h2'>Welcome to Organon with SHAH</h2>
          <h3 className='h3'>
            <i>Please Choose Your Category</i>
          </h3>

          <button className='ind-btn' onClick={() => handleOptionSelect('BHARAT')}>
            India
          </button>
          <button className='international-button' onClick={() => handleOptionSelect('INTERNATIONAL')}>
            International
          </button>
        </div>
      </div>
    )
  )
}

export default Popup
