import React from 'react'
import useWindowDimesions from '../components/useWindowDimesions'
import '../styles/coursecard.scss'
import RatingCard from './ratingCard'

const CourseCard = props => {
  const { width } = useWindowDimesions()

  const p = props.purchased === true ? '/view-course/' : '/'

  if (width > 999) {
    return (
      <>
        <div className='coursecard-1'>
          <img src={props.img === 'nnn' ? 'https://courseimg.s3.amazonaws.com/1.png' : props.img} alt={props.title} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              padding: '0.5rem 0',
              height: '100%',
              gap: '1rem'
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span className='main-title-card'>
                {props.title.split(':')[0]}
                <span className=''>
                  {props.title.split(':')[2] === undefined
                    ? props.title.split(':')[1]
                    : props.title.split(':')[1] + props.title.split(':')[2]}
                </span>{' '}
                <p style={{ color: 'orangered', fontSize: '12px', fontWeight: '700', margin: '0' }}>
                  {props?.one_line}
                </p>
              </span>
              <div className='border-div'></div>
              <div className='duration-time-div'>
                <span className='duration-time'>
                  {props?.chapterCount} Chapters | {props?.lessonCount} Lessons | {props.TotalDuration}
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  justifyContent: 'space-between'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    cursor: 'pointer',
                    alignItems: 'center'
                  }}
                  onClick={() => props.openModelFunction(props)}
                >
                  <span className='duration-time'>Course Preview</span>
                  <span className='duration-time'>:</span>
                  <span>
                    <i class='fa fa-play-circle-o' style={{ fontSize: '1.5rem', color: 'orangered' }}></i>
                  </span>
                </div>
                <RatingCard rating={props.rating} />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                cursor: 'pointer',
                alignItems: 'center'
              }}
            >
              <div
                className='read-more-buttons'
                onClick={() =>
                  props.published === true
                    ? (window.location.href = p + props.slug)
                    : alert('This course is Coming soon')
                }
              >
                <span>Read More</span>
              </div>
              <a
                href={props.courseCurriculum}
                target='_blank'
                className='btn-slide2 text-decoration-none'
                rel='noreferrer'
              >
                <span className='circle2'>
                  <i class='fa fa-download' style={{ fontSize: '15px' }}></i>
                </span>
                <span className='title2'>Curriculum</span>
                <span className='title-hover2'>Download</span>
              </a>
            </div>
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className='coursecard-1'>
          <img src={props.img === 'nnn' ? 'https://courseimg.s3.amazonaws.com/1.png' : props.img} alt={props.title} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              padding: '0.5rem 0',
              height: '100%',
              gap: '1rem'
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span className='main-title-card'>
                {props.title.split(':')[0]}:
                <span className=''>
                  {props.title.split(':')[2] === undefined
                    ? props.title.split(':')[1]
                    : props.title.split(':')[1] + props.title.split(':')[2]}
                </span>
                <p style={{ color: 'orangered', fontSize: '12px', fontWeight: '700', margin: '0' }}>
                  {props?.one_line}
                </p>
              </span>
              <div className='border-div'></div>
              <div className='duration-time-div'>
                <span className='duration-time'>
                  {props?.chapterCount} Chapters | {props?.lessonCount} Lessons | {props.TotalDuration}
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  justifyContent: 'space-between'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    cursor: 'pointer',
                    alignItems: 'center'
                  }}
                  onClick={() => props.openModelFunction(props)}
                >
                  <span className='duration-time'>Course Preview</span>
                  <span className='duration-time'>:</span>
                  <span>
                    <i class='fa fa-play-circle-o' style={{ fontSize: '1.5rem', color: 'orangered' }}></i>
                  </span>
                </div>
                <RatingCard rating={props.rating} />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                cursor: 'pointer',
                alignItems: 'center'
              }}
            >
              <div
                className='read-more-buttons'
                onClick={() =>
                  props.published === true
                    ? (window.location.href = p + props.slug)
                    : alert('This course is Coming soon')
                }
              >
                <span>Read More</span>
              </div>
              <a
                href={props.courseCurriculum}
                target='_blank'
                className='btn-slide2 text-decoration-none'
                rel='noreferrer'
              >
                <span className='circle2'>
                  <i class='fa fa-download' style={{ fontSize: '10px' }}></i>
                </span>
                <span className='title2'>Curriculum</span>
                <span className='title-hover2'>Download</span>
              </a>
            </div>
          </div>
        </div>
      </>
    )
  }
}

CourseCard.defaultProps = {
  title: 'Course Name',
  description: 'Course Description',
  img: 'https://wordpress-461136-1831741.cloudwaysapps.com/wp-content/uploads/2021/03/working-desk-with-digital-tablet-showing-digital-marketing-concept.jpg',
  chapters: '0',
  lessons: '0',
  slug: 'course-name'
}

export default CourseCard
